import React, { useContext } from "react";
import { Dropdown } from "react-bootstrap";
import { withRouter, Link, useHistory } from "react-router-dom";
import cogoToast from "cogo-toast";
import { Utility } from "../../Helpers/utils";
import { confirmAlert } from "react-confirm-alert";
import {
  ConfirmWrapper,
  PrimarySlickButton,
  SlickButton,
  Circle,
} from "../../components/AlertUtility";
import { AuthContext } from "../../context/auth-context";

const Navbar = () => {
  const user = Utility.getUser();
  const history = useHistory();

  const authState = useContext(AuthContext);

  const toggleOffcanvas = () => {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  };

  const toastoptions = {
    hideAfter: 5,
    position: "top-right",
    heading: "Attention",
  };

  const logout = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmWrapper className="sc-fzoLag BNtsP">
            <a
              className="sidebar-brand brand-logo"
              href="/laparrila/restaurant-manager/dashboard"
            >
              <img
                src={require("../../assets/images/la-parrila-logo.png")}
                alt="logo"
                className=""
                // style={{ margin: "0 auto", width: "130px" }}
              />
            </a>
            <header>
              {/* <h4></h4> */}
              <h6>Are you sure you want to log out of the system?</h6>
            </header>
            <div className="alert-footer">
              <PrimarySlickButton
                type="submit"
                onClick={() => {
                  // AuthService.logout();
                  authState.logout();
                  localStorage.clear();
                  sessionStorage.clear();
                  onClose();
                  cogoToast.success(
                    "User logged out successfully",
                    toastoptions
                  );
                  history.push("/home/login");
                }}
                className="sc-fznZeY sc-fzqBZW eNQuho"
              >
                <span>Yes, log out!</span>
              </PrimarySlickButton>
              <SlickButton
                onClick={onClose}
                type="reset"
                className="sc-fznZeY gJlwEu cancel"
              >
                <span>Stay signed in</span>
              </SlickButton>
            </div>
          </ConfirmWrapper>
        );
      },
    });
  };
  // const { user } = this.state;
  // console.log("Navbar", user);
  return (
    <nav className="navbar col-lg-12 col-12 p-lg-0 fixed-top d-flex flex-row">
      <div className="navbar-menu-wrapper d-flex align-items-stretch justify-content-between">
        <a
          className="navbar-brand brand-logo-mini align-self-center d-lg-none"
          href="!#"
          onClick={(evt) => evt.preventDefault()}
        >
          <img src={require("../../assets/images/logo-mini.svg")} alt="logo" />
        </a>
        <button
          className="navbar-toggler navbar-toggler align-self-center mr-2"
          type="button"
          onClick={() => document.body.classList.toggle("sidebar-icon-only")}
        >
          <i className="mdi mdi-menu"></i>
        </button>
        <ul className="navbar-nav">
          {/* <li className="nav-item navbar-dropdown-large">
            <Dropdown>
              <Dropdown.Toggle className="nav-link count-indicator border-0">
                <i className="mdi mdi-bell-outline"></i>
                <span className="count count-varient1">7</span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="preview-list navbar-dropdown navbar-dropdown-large">
                <h6 className="p-3 mb-0 ">Notifications</h6>
                <Dropdown.Item
                  className="dropdown-item preview-item d-flex align-items-center"
                  href="!#"
                  onClick={(evt) => evt.preventDefault()}
                >
                  <div className="preview-thumbnail">
                    <img
                      src={require("../../assets/images/faces/face4.jpg")}
                      alt=""
                      className="profile-pic"
                    />
                  </div>
                  <div className="preview-item-content">
                    <p className="mb-0">
                      Dany Miles{" "}
                      <span className="text-small text-muted">
                        commented on your photo
                      </span>
                    </p>
                  </div>
                </Dropdown.Item>
                <Dropdown.Item
                  className="dropdown-item preview-item d-flex align-items-center"
                  href="!#"
                  onClick={(evt) => evt.preventDefault()}
                >
                  <div className="preview-thumbnail">
                    <img
                      src={require("../../assets/images/faces/face3.jpg")}
                      alt=""
                      className="profile-pic"
                    />
                  </div>
                  <div className="preview-item-content">
                    <p className="mb-0">
                      James{" "}
                      <span className="text-small text-muted">
                        posted a photo on your wall
                      </span>
                    </p>
                  </div>
                </Dropdown.Item>
                <Dropdown.Item
                  className="dropdown-item preview-item d-flex align-items-center"
                  href="!#"
                  onClick={(evt) => evt.preventDefault()}
                >
                  <div className="preview-thumbnail">
                    <img
                      src={require("../../assets/images/faces/face2.jpg")}
                      alt=""
                      className="profile-pic"
                    />
                  </div>
                  <div className="preview-item-content">
                    <p className="mb-0">
                      Alex{" "}
                      <span className="text-small text-muted">
                        just mentioned you in his post
                      </span>
                    </p>
                  </div>
                </Dropdown.Item>
                <Dropdown.Item
                  className="dropdown-item preview-item d-flex align-items-center"
                  href="!#"
                  onClick={(evt) => evt.preventDefault()}
                >
                  <p className="p-3 mb-0 ">View all activities</p>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li> */}
          {/* <li className="nav-item">
            <Dropdown>
              <Dropdown.Toggle className="nav-link count-indicator border-0">
                <i className="mdi mdi-email-outline"></i>
                <span className="count  count-varient2">5</span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="preview-list navbar-dropdown navbar-dropdown-large">
                <h6 className="p-3 mb-0 ">Messages</h6>
                <Dropdown.Item
                  className="dropdown-item preview-item d-flex align-items-center"
                  href="!#"
                  onClick={(evt) => evt.preventDefault()}
                >
                  <div className="preview-item-content flex-grow">
                    <span className="badge badge-pill badge-success">
                      Request
                    </span>
                    <p className="text-small text-muted ellipsis mb-0">
                      {" "}
                      Suport needed for user 123{" "}
                    </p>
                  </div>
                  <p className="text-small text-muted align-self-start">
                    4:10 PM
                  </p>
                </Dropdown.Item>
                <Dropdown.Item
                  className="dropdown-item preview-item d-flex align-items-center"
                  href="!#"
                  onClick={(evt) => evt.preventDefault()}
                >
                  <div className="preview-item-content flex-grow">
                    <span className="badge badge-pill badge-warning">
                      Invoices
                    </span>
                    <p className="text-small text-muted ellipsis mb-0">
                      {" "}
                      Invoice for order is mailed{" "}
                    </p>
                  </div>
                  <p className="text-small text-muted align-self-start">
                    4:10 PM
                  </p>
                </Dropdown.Item>
                <Dropdown.Item
                  className="dropdown-item preview-item d-flex align-items-center"
                  href="!#"
                  onClick={(evt) => evt.preventDefault()}
                >
                  <div className="preview-item-content flex-grow">
                    <span className="badge badge-pill badge-danger">
                      Projects
                    </span>
                    <p className="text-small text-muted ellipsis mb-0">
                      {" "}
                      New project will start tomorrow{" "}
                    </p>
                  </div>
                  <p className="text-small text-muted align-self-start">
                    4:10 PM
                  </p>
                </Dropdown.Item>
                <Dropdown.Item
                  className="dropdown-item preview-item d-flex align-items-center"
                  href="!#"
                  onClick={(evt) => evt.preventDefault()}
                >
                  <h6 className="p-3 mb-0 ">See all activity</h6>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li> */}
          <li className="nav-item nav-search border-0 ml-1 ml-md-3 ml-lg-5 d-none d-md-flex">
            {/* <form className="nav-link form-inline mt-2 mt-md-0">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                />
                <div className="input-group-append">
                  <span className="input-group-text">
                    <i className="mdi mdi-magnify"></i>
                  </span>
                </div>
              </div>
            </form> */}
          </li>
        </ul>
        <ul className="navbar-nav navbar-nav-right">
          {/* <li className="nav-item d-none d-xl-flex border-0">
              <Dropdown>
                <Dropdown.Toggle className="nav-link count-indicator bg-transparent">
                <i className="mdi mdi-earth mr-2"></i> English&nbsp;
                </Dropdown.Toggle>
                <Dropdown.Menu className="preview-list navbar-dropdown">
                  <Dropdown.Item className="dropdown-item  preview-itemd-flex align-items-center" href="!#" onClick={evt =>evt.preventDefault()}>
                  French
                  </Dropdown.Item>
                  <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center" href="!#" onClick={evt =>evt.preventDefault()}>
                  Spain
                  </Dropdown.Item>
                  <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center" href="!#" onClick={evt =>evt.preventDefault()}>
                  Latin
                  </Dropdown.Item>
                    <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center" href="!#" onClick={evt =>evt.preventDefault()}>
                    Japanese
                    </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li> */}
          <li className="nav-item  nav-profile border-0">
            <Dropdown>
              <Dropdown.Toggle className="nav-link count-indicator bg-transparent">
                <Circle
                  style={{
                    backgroundImage: `url(${process.env.REACT_APP_AZURE_STORAGE}/pictures/${user?.slug}),
                         url(/svg/portrait.svg)`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    position: "absolute",
                    left: "-60px",
                    top: "-20px",
                  }}
                />

                <span
                  className="profile-name"
                  style={{ position: "relative", top: "-10px" }}
                >
                  {" "}
                  {user && user.first_name + " " + user.last_name}{" "}
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="preview-list navbar-dropdown">
                <span>
                  {user && user.role === "super admin" && (
                    <Dropdown.Item
                      className="dropdown-item preview-item d-flex align-items-center"
                      href="!#"
                      onClick={(evt) => evt.preventDefault()}
                    >
                      <i className="mdi mdi-cached text-success"></i> Activity
                      Log
                    </Dropdown.Item>
                  )}
                </span>

                {user && (
                  <Link
                    to={`/employees/view/${user.slug}`}
                    className="dropdown-item preview-item d-flex align-items-center"
                  >
                    <i className="mdi mdi-account text-primary"></i> Profile
                  </Link>
                )}
                <Dropdown.Item
                  className="dropdown-item preview-item d-flex align-items-center"
                  onClick={() => logout()}
                >
                  <i className="mdi mdi-logout text-primary"></i> Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </ul>
        <button
          className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
          type="button"
          onClick={() => toggleOffcanvas()}
        >
          <span className="mdi mdi-menu"></span>
        </button>
      </div>
    </nav>
  );
};

export default withRouter(Navbar);
