import React, { useRef } from "react";
import IdleTimer from "react-idle-timer";
import { AuthService } from "../service/authService";
import cogoToast from "cogo-toast";
import { useHistory } from "react-router";
import {
  // PrimarySlickButton,
  // SlickButton,
  ConfirmWrapper,
} from "./AlertUtility";
import { confirmAlert } from "react-confirm-alert";

const IdleTimerContainer = () => {
  const idleTimerRef = useRef(null);
  const sessionTimeoutRef = useRef(null);

  const history = useHistory();

  const toastoptions = {
    hideAfter: 5,
    position: "top-right",
    heading: "Attention",
  };

  const logMeOut = () => {
    setTimeout(() => {
      AuthService.logout();
      localStorage.clear();
      sessionStorage.clear();
      cogoToast.success("User logged out successfully", toastoptions);
      history.push("/home/login");
      window.location.reload();
    }, 4000);
  };

  const onIdle = () => {
    console.log("The user is idle");
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmWrapper className="sc-fzoLag BNtsP">
            <a
              className="sidebar-brand brand-logo"
              href="/laparrila/restaurant-manager/dashboard"
            >
              <img
                src={require("../assets/images/la-parrila-logo.png")}
                alt="logo"
                className=""
              />
            </a>
            <header>
              {/* <h4></h4> */}
              <h4>
                Hey there, you have been idle for a while. You will be logged
                out of the system now.
              </h4>
            </header>
            <div className="alert-footer">
              {/* <PrimarySlickButton
                type="submit"
                onClick={() => {
                  AuthService.logout();
                  localStorage.clear();
                  sessionStorage.clear();
                  onClose();
                  cogoToast.success(
                    "User logged out successfully",
                    toastoptions
                  );
                  history.push("/home/login");
                }}
                className="sc-fznZeY sc-fzqBZW eNQuho"
              >
                <span>Yes, log out!</span>
              </PrimarySlickButton>
              <SlickButton
                onClick={() => {
                  onClose();
                  clearTimeout(sessionTimeoutRef.current);
                }}
                type="reset"
                className="sc-fznZeY gJlwEu cancel"
              >
                <span>Stay signed in</span>
              </SlickButton> */}
            </div>
          </ConfirmWrapper>
        );
      },
    });

    sessionTimeoutRef.current = logMeOut();
    // onClose();
  };
  return (
    <div>
      <IdleTimer
        ref={idleTimerRef}
        // timeout={600 * 1000}
        timeout={1800000}
        onIdle={onIdle}
      ></IdleTimer>
    </div>
  );
};

export default IdleTimerContainer;
