import React, { useEffect, createContext, useState } from "react";
import { useHistory } from "react-router";
import { AuthService } from "../service/authService";
import cogoToast from "cogo-toast";
import { Utility } from "../Helpers/utils";

const toastoptions = {
  hideAfter: 5,
  position: "top-right",
  heading: "Attention",
};

const defaultState = {
  user: null,
  isLoading: false,
  login: async (email, password) => {},
  logout: () => {},
  refresh: () => {},
  message: "This is a very important message for us all",
};

export const AuthContext = createContext(defaultState);
export const AuthConsumer = AuthContext.Consumer;

export const AuthProvider = ({ children }) => {
  // const { state } = useLocation();
  const history = useHistory();
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    // setReady(true)
    // if (state) {
    refresh();
    // }
    // eslint-disable-next-line
  }, []);

  const login = async (email, password, redirectTo = "") => {
    AuthService.login(email, password)
      .then((response) => {
        if (response.data.success) {
          if (response.data.data?.user.first_login) {
            cogoToast.info(
              "You have to change your password before logging in",
              toastoptions
            );
            history.push("/auth/first-login", { email });
          } else {
            setUser(response.data.data.user);
            Utility.setUser(response.data.data.user);
            Utility.setToken(response.data.data.access_token);
            cogoToast.success("User logged in successfully", toastoptions);
            history.push("/dashboard");
          }
        }
      })
      .catch((error) => {
        cogoToast.error(error.response.data.message, toastoptions);
      });
  };

  const logout = async () => {
    setIsLoading(true);
    AuthService.logout();
    setUser(null);
    cogoToast.success("User logged out successfully", toastoptions);
    history.push("/login");
    setIsLoading(false);
  };

  const refresh = async (redirectIfValid = "") => {
    setIsLoading(true);
    try {
      const response = await AuthService.getAuth();
      setReady(true);

      if (!response.success) {
        setUser(null);
        setIsLoading(false);
        return;
      }

      setUser(response.data);

      if (redirectIfValid) {
        history.replace(redirectIfValid);
      }
    } catch (e) {
      setUser(null);
    }

    setIsLoading(false);
    setReady(true);
  };

  return (
    <AuthContext.Provider
      value={{ user, isLoading, login, logout, refresh, ready }}
    >
      {children}
    </AuthContext.Provider>
  );
};
