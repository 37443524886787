import styled from "styled-components";

export const ConfirmWrapper = styled.div`
  background: #ffffffee;
  border-radius: 2.5px;
  padding: 40px;
  width: 100vw;
  max-width: 500px;
  backdrop-filter: blur(20px);
  display: block;
  flex-direction: column;

  > header {
    color: #555a55;
    margin-bottom: 30px;

    * + * {
      margin-top: 10px;
    }
  }

  > .alert-footer {
    display: flex;
    align-items: flex-end;
    flex: 1;

    > * + * {
      margin-left: 20px;
    }
  }
`;

export const SlickButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  height: 40px;
  background: #181a1f;
  border: 2px solid #181a1f;
  box-sizing: border-box;
  border-radius: 20px;
  outline: none;
  transition: all 0.5s ease;
  font-size: 85%;
  font-weight: bold;

  &:hover {
    background: #606662;
    border-color: #606662;
  }

  * {
    color: white;
  }
`;

export const PrimarySlickButton = styled(SlickButton)`
  background: #3f50f6;
  border-color: #3f50f6;

  &:hover {
    background: #4758f7;
    border-color: #4758f7;
  }
`;

export const Circle = styled.div`
  width: 40px;
  height: 40px;
  background: #d2d7d6;
  border-radius: 100%;
`;

export const BigCircle = styled.div`
  width: 100px;
  height: 100px;
  background: #d2d7d6;
  border-radius: 100%;
`;

export const BiggerCircle = styled.div`
  width: 150px;
  height: 150px;
  background: #d2d7d6;
  border-radius: 100%;
`;
