import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Utility } from "./utils";
import cogoToast from "cogo-toast";

const Authorization = (allowedRoles) => (WrappedComponent) => {
  return class withAuthorization extends Component {
    constructor(props) {
      super(props);

      this.state = {
        user: Utility.getUser(),
      };
    }

    toastoptions = {
      hideAfter: 5,
      position: "top-right",
      heading: "Attention",
    };

    render() {
      const { role } = this.state.user;
      if (allowedRoles.includes(role)) {
        return <WrappedComponent {...this.props} />;
      } else {
        cogoToast.error(
          "You are not allowed to access that page",
          this.toastOptions
        );
        return (
          <Redirect
            to={{
              pathname: "/dashboard",
            }}
          />
        );
      }
    }
  };
};

export default Authorization;
