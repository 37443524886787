import axios from "axios";
// import NProgress from 'nprogress';
import { Utility } from "../Helpers/utils";
import cogoToast from "cogo-toast";

const Api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Cache-Control": "no-cache",
  },
});

const toastOptions = {
  hideAfter: 5,
  position: "top-right",
  heading: "Attention",
};

Api.interceptors.response.use(
  function (response) {
    // NProgress.done();
    return response;
  },
  function (error) {
    // NProgress.done(),
    if (error.message === "Network Error") {
      cogoToast.error(
        "Network error.Make sure that the API is running",
        toastOptions
      );
    }
    if (error.response.status === "401") {
      localStorage.clear();
      sessionStorage.clear();
      Utility.unSetUser();
      Utility.unSetToken();
      cogoToast.error(error.response.data.message, toastOptions);
    }

    if (error.response.status === "500") {
      cogoToast.error(
        "Server error - Check the terminal for more info",
        toastOptions
      );
    }

    // if (!error.status === "Network Error" && !error.response) {

    return Promise.reject(error);
  }
);

Api.interceptors.request.use(
  (config) => {
    let access_token = Utility.getToken();
    if (access_token) {
      config.headers["Authorization"] = `Bearer ${access_token}`;
    }
    // NProgress.start();
    return config;
  },
  (error) => {
    // NProgress.done();
    return Promise.reject(error);
  }
);

export default Api;
