export class Utility {
  static getToken() {
    const value = localStorage.getItem("API_TOKEN");
    try {
      return value ? JSON.parse(value) : null;
    } catch (e) {
      return null;
    }
  }

  static getUser() {
    const value = localStorage.getItem("API_USER");
    try {
      return value ? JSON.parse(value) : null;
    } catch (e) {
      return null;
    }
  }

  static getBearerToken() {
    if (this.getToken()) return `Bearer ${this.getToken()}`;
    return null;
  }

  static setToken(token) {
    return localStorage.setItem("API_TOKEN", JSON.stringify(token));
  }

  static setUser(user) {
    return localStorage.setItem("API_USER", JSON.stringify(user));
  }

  static unSetToken() {
    return localStorage.removeItem("API_TOKEN");
  }

  static unSetUser() {
    return localStorage.removeItem("API_USER");
  }

  static setItemSession(item, name) {
    return sessionStorage.setItem(name, JSON.stringify(item));
  }
}
