const bonusReducer = (state, { type, payload }) => {
  switch (type) {
    case "SET_INITIAL_DATA":
      return {
        ...state,
        storeId: payload.storeId,
        year: payload.year,
        quarter: payload.quarter,
        months: determineMonth(payload.quarter),
      };
    case "SET_SALES_DATA":
      return {
        ...state,
        sales: { ...state.sales, [payload.key]: payload.value },
      };
    case "SET_COGS_DATA":
      return {
        ...state,
        cogs: { ...state.cogs, [payload.key]: payload.value },
      };
    case "SET_GROSS_PROFIT_DATA":
      return {
        ...state,
        grossProfit: { ...state.grossProfit, [payload.key]: payload.value },
      };
    case "SET_OPERATING_EXPENSES_DATA":
      return {
        ...state,
        operatingExpenses: {
          ...state.operatingExpenses,
          [payload.key]: payload.value,
        },
      };
    case "SET_ADMINISTRATIVE_EXPENSES_DATA":
      return {
        ...state,
        sales: payload.sales,
      };
    case "SET_TOTAL_EXPENSES_DATA":
      return {
        ...state,
        sales: payload.sales,
      };
    case "SET_OPERATING_INCOME_DATA":
      return {
        ...state,
        sales: payload.sales,
      };
    default:
      throw new Error(`Unknown action type: ${type}`);
  }
};

const determineMonth = (quarter) => {
  var firstMonth, secondMonth, thirdMonth;
  switch (quarter) {
    case "1":
      firstMonth = "Jan";
      secondMonth = "Feb";
      thirdMonth = "Mar";
      break;
    case "2":
      firstMonth = "April";
      secondMonth = "May";
      thirdMonth = "June";
      break;
    case "3":
      firstMonth = "Jul";
      secondMonth = "Aug";
      thirdMonth = "Sept";
      break;
    case "4":
      firstMonth = "Oct";
      secondMonth = "Nov";
      thirdMonth = "Dec";
      break;
    default:
      throw new Error(`Unknown quarter: ${quarter}`);
  }

  return { firstMonth, secondMonth, thirdMonth };
};
export default bonusReducer;
