import Api from "./Api";
import { Utility } from "../Helpers/utils";

export class AuthService {
  static async getAuth() {
    return new Promise((resolve, reject) => {
      return Api.get("auth/user")
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async firstLogin(email, password) {
    return new Promise((resolve, reject) => {
      return Api.post("auth/first-login", { email, password })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async login(email, password) {
    return new Promise((resolve, reject) => {
      return Api.post("auth/login", { email, password })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async adminLogin(email, password) {
    return new Promise((resolve, reject) => {
      return Api.post("auth/admin/login", { email, password })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async sendResetEmail(data) {
    return new Promise((resolve, reject) => {
      return Api.post("password/email", data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async resetPassword(data) {
    return new Promise((resolve, reject) => {
      return Api.post("password/reset", data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async logout() {
    const response = await Api.get("auth/logout");

    if (response.data.success) {
      Utility.unSetToken();
      Utility.unSetUser();
      window.localStorage.clear();
      window.sessionStorage.clear();
      delete Api.defaults.headers.common["Authorization"];
    }
  }
}
