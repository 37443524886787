import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Collapse } from "react-bootstrap";
// import { Trans } from 'react-i18next';
import { AuthService } from "../../service/authService";
import cogoToast from "cogo-toast";
import { confirmAlert } from "react-confirm-alert";
import { ConfirmWrapper, PrimarySlickButton, SlickButton } from "../../components/AlertUtility";
import styled from "styled-components";

const Circle = styled(Link)`
  width: 40px;
  height: 40px;
  background: #d2d7d6;
  border-radius: 100%;
`;

const Div = styled.div`
  width: 280px;
  padding: 50px 50px 50px 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > header {
    display: flex;
    color: #141212;
    font-weight: bold;
  }

  > header ${Circle} {
    flex-shrink: 0;
  }

  > header > section {
    margin-left: 20px;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 5px;
    overflow: hidden;
    white-space: nowrap;

    * {
      text-overflow: ellipsis;
    }
  }

  > header > section > a {
    color: #141212;
    text-decoration: none;
  }

  > nav {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 40px;
  }

  > nav > a,
  > section > button {
    appearance: none;
    color: #717a87;
    display: flex;
    align-items: center;
    font-size: 100%;
    background: transparent;
    outline: none;
    border: none;
    font-weight: bold;
    margin: 0;
    padding: 0;
    cursor: pointer;

    svg {
      width: 20px;
      height: 20px;
      margin-right: 20px;
    }

    &.active {
      color: white;
    }
  }

  @media screen and (max-width: 1200px) {
    width: 80px;
    padding: 80px 30px;

    > nav > a svg,
    > section > button svg {
      margin: 0;
    }

    ${Circle} {
      width: 20px;
      height: 20px;
    }

    > header > section,
    > nav > a span,
    > section > button span {
      display: none;
    }
  }

  @media screen and (max-width: 500px) {
    width: 60px;
    padding: 20px;
  }
`;

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.logout = this.logout.bind(this);
  }

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach((i) => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  toastoptions = {
    hideAfter: 5,
    position: "top-right",
    heading: "Attention",
  };

  logout() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmWrapper className="sc-fzoLag BNtsP">
            <a className="sidebar-brand brand-logo" href="/laparrila/restaurant-manager/dashboard">
              <img
                src={require("../../assets/images/la-parrila-logo.png")}
                alt="logo"
                className=""
              />
            </a>
            <header>
              {/* <h4></h4> */}
              <h6>Are you sure you want to log out of the system?</h6>
            </header>
            <div className="alert-footer">
              <PrimarySlickButton
                type="submit"
                onClick={() => {
                  AuthService.logout();
                  localStorage.clear();
                  sessionStorage.clear();
                  onClose();
                  cogoToast.success("User logged out successfully", this.toastoptions);
                  this.props.history.push("/home/login");
                }}
                className="sc-fznZeY sc-fzqBZW eNQuho"
              >
                <span>Yes, log out!</span>
              </PrimarySlickButton>
              <SlickButton onClick={onClose} type="reset" className="sc-fznZeY gJlwEu cancel">
                <span>Stay signed in</span>
              </SlickButton>
            </div>
          </ConfirmWrapper>
        );
      },
    });
  }

  onRouteChanged() {
    document.querySelector("#sidebar").classList.remove("active");
    Object.keys(this.state).forEach((i) => {
      this.setState({ [i]: false });
    });

    const dropdownPaths = [
      { path: "/apps", state: "appsMenuOpen" },
      { path: "/employees", state: "employeesMenuOpen" },
      { path: "/emails", state: "appEmails" },
      { path: "/notifications", state: "appNotifications" },
      { path: "/financials", state: "financialsMenu" },
      { path: "/catering", state: "cateringMenu" },
      { path: "/hr", state: "hrMenuOpen" },
      { path: "/human-resources", state: "humanResourcesMenu" },
      { path: "/hr/emergency", state: "emergency" },
      { path: "/accounting", state: "accountingMenuOpen" },
      { path: "/bonus", state: "bonusMenuOpen" },
      { path: "/system-configuration", state: "systemConfigurationMenuOpen" },
      { path: "/form-manager", state: "formElementsMenuOpen" },
      { path: "/user-pages", state: "userPagesMenuOpen" },
      { path: "/general-pages", state: "generalPagesMenuOpen" },
      { path: "/report", state: "reportPagesMenuOpen" },
    ];

    dropdownPaths.forEach((obj) => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true });
      }
    });
  }
  render() {
    const { user } = this.props;
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <div className="text-center sidebar-brand-wrapper d-flex align-items-center">
          <a className="sidebar-brand brand-logo" href="index.html">
            <img src={require("../../assets/images/la-parrila-logo.png")} alt="logo" />
          </a>
          <a className="sidebar-brand brand-logo-mini pl-4 pt-3" href="index.html">
            <img src={require("../../assets/images/logo-mini.svg")} alt="logo" />
          </a>
        </div>
        <ul className="nav">
          <li className="nav-item nav-profile">
            {user && (
              <Div>
                <header>
                  <Circle
                    to={`/employees/view/${user.slug}`}
                    style={{
                      backgroundImage: `url(${process.env.REACT_APP_AZURE_STORAGE}/pictures/${user?.slug}),
                     url(/svg/portrait.svg)`,
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                    }}
                  />
                  <section>
                    <span>{user.first_name + " " + user.last_name}</span>
                    <Link to={`/employee/view/my-profile/${user.slug}`}>
                      <small>View Profile</small>
                    </Link>
                  </section>
                </header>
              </Div>
            )}
          </li>

          <li className={this.isPathActive("/dashboard") ? "nav-item active" : "nav-item"}>
            <Link className="nav-link" to="/dashboard">
              <i className="mdi mdi-home menu-icon"></i>
              <span className="menu-title">Dashboard</span>
            </Link>
          </li>

          {user ? (
            <li className={this.isPathActive("/employees") ? "nav-item active" : "nav-item"}>
              <div>
                <div
                  className={this.state.employeesMenuOpen ? "nav-link menu-expanded" : "nav-link"}
                  onClick={() => this.toggleMenuState("employeesMenuOpen")}
                  data-toggle="collapse"
                >
                  <i className="mdi mdi-account-multiple menu-icon"></i>
                  <span className="menu-title">Employees</span>
                  <i className="menu-arrow"></i>
                </div>

                <Collapse in={this.state.employeesMenuOpen}>
                  <ul className="nav flex-column sub-menu">
                    {user && user.role !== "super admin" && (
                      <li className="nav-item">
                        {" "}
                        <Link
                          className={
                            this.isPathActive("/employees/view/my-profile")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to={`/employee/view/my-profile/${user.slug}`}
                        >
                          View My Profile
                        </Link>
                      </li>
                    )}

                    {user && user.role === "employee" && (
                      <li className="nav-item">
                        {" "}
                        <Link
                          className={
                            this.isPathActive("/employees/rate-your-leaders")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to={`/employees/rate-your-leaders`}
                        >
                          Rate Your Leaders
                        </Link>
                      </li>
                    )}

                    {(user && user.role === "super admin") || user.role === "hr" ? (
                      <li className="nav-item">
                        {" "}
                        <Link
                          className={
                            this.isPathActive("/employees/list") ? "nav-link active" : "nav-link"
                          }
                          to="/employees/list"
                        >
                          View Employees
                        </Link>
                      </li>
                    ) : (
                      ""
                    )}

                    {user && (user.role === "super admin" || user.role === "hr") ? (
                      <li className="nav-item">
                        {" "}
                        <Link
                          className={
                            this.isPathActive("/employees/add") ? "nav-link active" : "nav-link"
                          }
                          to="/employees/add"
                        >
                          {" "}
                          Add Employee{" "}
                        </Link>
                      </li>
                    ) : (
                      ""
                    )}

                    {user &&
                    (user.role === "super admin" ||
                      user.role === "restaurant manager" ||
                      user.role === "service manager" ||
                      user.role === "hr" ||
                      user.role === "operations") ? (
                      <li className="nav-item">
                        {" "}
                        <Link
                          className={
                            this.isPathActive("/employees/application")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to="/employees/application"
                        >
                          Application Entries
                        </Link>
                      </li>
                    ) : (
                      ""
                    )}
                  </ul>
                </Collapse>
              </div>
            </li>
          ) : (
            ""
          )}

          {/* {user && user.role !== "employee" ? (
            <li
              className={
                this.isPathActive("/notifications")
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <div
                className={
                  this.state.appNotifications
                    ? "nav-link menu-expanded"
                    : "nav-link"
                }
                onClick={() => this.toggleMenuState("appNotifications")}
                data-toggle="collapse"
              >
                <i className="mdi mdi-bell-ring menu-icon"></i>
                <span className="menu-title">Notifications</span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.appNotifications}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/notifications")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/notifications"
                    >
                      View all
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </li>
          ) : (
            ""
          )} */}

          {user && user.role !== "employee" ? (
            <li className={this.isPathActive("/form-manager") ? "nav-item active" : "nav-item"}>
              <div
                className={this.state.formElementsMenuOpen ? "nav-link menu-expanded" : "nav-link"}
                onClick={() => this.toggleMenuState("formElementsMenuOpen")}
                data-toggle="collapse"
              >
                <i className="mdi mdi-microsoft menu-icon"></i>
                <span className="menu-title">Form Manager</span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.formElementsMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  {user &&
                  (user.role === "restaurant manager" ||
                    user.role === "service manager" ||
                    user.role === "operations" ||
                    user.role === "super admin" ||
                    user.role === "catering") ? (
                    <div>
                      <li className="nav-item">
                        {" "}
                        <Link
                          className={
                            this.isPathActive("/form-manager/restaurant-manager")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to="/form-manager/restaurant-manager"
                        >
                          Restaurant Manager
                        </Link>
                      </li>
                    </div>
                  ) : (
                    ""
                  )}
                </ul>
              </Collapse>
            </li>
          ) : (
            ""
          )}
          <li className={this.isPathActive("/financials") ? "nav-item active" : "nav-item"}>
            {user &&
            (user.role === "super admin" ||
              user.role === "accounting" ||
              user.role === "restaurant manager" ||
              user.role === "service manager" ||
              user.role === "district manager" ||
              user.role === "operations" ||
              user.role === "catering") ? (
              <div>
                <div
                  className={this.state.financialsMenu ? "nav-link menu-expanded" : "nav-link"}
                  onClick={() => this.toggleMenuState("financialsMenu")}
                  data-toggle="collapse"
                >
                  <i className="mdi mdi-currency-usd menu-icon"></i>
                  <span className="menu-title">Financials</span>
                  <i className="menu-arrow"></i>
                </div>
                <Collapse in={this.state.financialsMenu}>
                  <ul className="nav flex-column sub-menu">
                    <li className="nav-item">
                      <Link
                        className={
                          this.isPathActive("/financials/report-history")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/financials/view-store-report"
                      >
                        Final Reports
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        className={
                          this.isPathActive("/financials/payment-history")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/financials/payment-history"
                      >
                        Payment History
                      </Link>
                      {/* )} */}
                    </li>
                    <li className="nav-item">
                      {user &&
                      (user.role === "super admin" ||
                        user.role === "accounting" ||
                        user.role === "restaurant manager" ||
                        user.role === "operations" ||
                        user.role === "service manager") ? (
                        <Link
                          className={
                            this.isPathActive("/financials/scheduled-payments")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to="/financials/scheduled-payments"
                        >
                          Scheduled Payments
                        </Link>
                      ) : (
                        ""
                      )}
                    </li>

                    <li className="nav-item">
                      {user &&
                      (user.role === "super admin" ||
                        user.role === "accounting" ||
                        user.role === "restaurant manager" ||
                        user.role === "operations" ||
                        user.role === "service manager") ? (
                        <Link
                          className={
                            this.isPathActive("/financials/pending-payments")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to="/financials/pending-payments"
                        >
                          Pending Payments
                        </Link>
                      ) : (
                        ""
                      )}
                    </li>

                    <li className="nav-item">
                      {user &&
                      (user.role === "super admin" ||
                        user.role === "accounting" ||
                        user.role === "restaurant manager" ||
                        user.role === "operations" ||
                        user.role === "service manager") ? (
                        <Link
                          className={
                            this.isPathActive("/financials/deleted-payments")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to="/financials/deleted-payments"
                        >
                          Deleted Payments
                        </Link>
                      ) : (
                        ""
                      )}
                    </li>
                    {/* to={`/financials/view-expenses-store/${value.id}`} */}
                    <li className="nav-item">
                      <Link
                        className={
                          this.isPathActive("/financials/expenses-viewers")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/financials/expenses-viewers"
                      >
                        Expenses Viewer
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        className={
                          this.isPathActive("/financials/instant-audits")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/financials/instant-audits"
                      >
                        Instant Pay Audit
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        className={
                          this.isPathActive("/financials/instant-transaction-history")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/financials/instant-transaction-history"
                      >
                        Instant History
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        className={
                          this.isPathActive("/financials/emergency-code-report")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/financials/emergency-code-report"
                      >
                        Emergency Code Report
                      </Link>
                    </li>
                  </ul>
                </Collapse>
              </div>
            ) : (
              ""
            )}
          </li>
          <li className={this.isPathActive("/catering") ? "nav-item active" : "nav-item"}>
            {user &&
            (user.role === "super admin" ||
              user.role === "accounting" ||
              user.role === "restaurant manager" ||
              user.role === "service manager" ||
              user.role === "district manager" ||
              user.role === "catering") ? (
              <div>
                <div
                  className={this.state.cateringMenu ? "nav-link menu-expanded" : "nav-link"}
                  onClick={() => this.toggleMenuState("cateringMenu")}
                  data-toggle="collapse"
                >
                  <i className="mdi mdi-food menu-icon"></i>
                  <span className="menu-title">Catering</span>
                  <i className="menu-arrow"></i>
                </div>
                <Collapse in={this.state.cateringMenu}>
                  <ul className="nav flex-column sub-menu">
                    <li className="nav-item">
                      <Link
                        className={
                          this.isPathActive("catering/catering-payment")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/catering/catering-payment"
                      >
                        Catering Event
                      </Link>
                    </li>

                    {/* <li className="nav-item">
                      <Link
                        className={
                          this.isPathActive("/catering/pending-list")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/catering/pending-list"
                      >
                        Pending Tips
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        className={
                          this.isPathActive("/catering/scheduled-list")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/catering/scheduled-list"
                      >
                        Scheduled Failed Tips
                      </Link>
                    </li> */}

                    <li className="nav-item">
                      <Link
                        className={
                          this.isPathActive("catering/process-staff-payment")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/catering/process-staff-payment"
                      >
                        Payment
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        className={
                          this.isPathActive("/catering/scheduled-events")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/catering/scheduled-events"
                      >
                        Scheduled Events
                      </Link>
                    </li>
                  </ul>
                </Collapse>
              </div>
            ) : (
              ""
            )}
          </li>
          {user &&
          (user.role === "super admin" || user.role === "accounting" || user.role === "hr") ? (
            <li className={this.isPathActive("/accounting") ? "nav-item active" : "nav-item"}>
              <div
                className={this.state.accountingMenuOpen ? "nav-link menu-expanded" : "nav-link"}
                onClick={() => this.toggleMenuState("accountingMenuOpen")}
                data-toggle="collapse"
              >
                <i className="mdi mdi-account-card-details menu-icon"></i>
                <span className="menu-title">Accounting</span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.accountingMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/accounting/report") ? "nav-link active" : "nav-link"
                      }
                      to="/accounting/report"
                    >
                      Closing Report
                    </Link>
                  </li>

                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/percentage/report") ? "nav-link active" : "nav-link"
                      }
                      to="/percentage/report"
                    >
                      % Sales Report
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </li>
          ) : (
            ""
          )}

          {user &&
          (user.role === "super admin" ||
            user.role === "accounting" ||
            user.role === "operations" ||
            user.role === "hr") ? (
            <li className={this.isPathActive("/bonus") ? "nav-item active" : "nav-item"}>
              <div
                className={this.state.bonusMenuOpen ? "nav-link menu-expanded" : "nav-link"}
                onClick={() => this.toggleMenuState("bonusMenuOpen")}
                data-toggle="collapse"
              >
                <i className="mdi mdi-account-card-details menu-icon"></i>
                <span className="menu-title">Bonus</span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.bonusMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/bonus-payout") ? "nav-link active" : "nav-link"
                      }
                      to="/bonus-payout"
                    >
                      Create Data
                    </Link>
                  </li>

                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/bonus-payout") ? "nav-link active" : "nav-link"
                      }
                      to="/bonus/bulk-upload"
                    >
                      Bulk Upload
                    </Link>
                  </li>

                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/bonus/view-select-bonus-payout")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/bonus/view-select-bonus-payout"
                    >
                      View Data
                    </Link>
                  </li>

                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/bonus/view-reviews-bonus-payout")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/bonus/view-reviews-bonus-payout"
                    >
                      Reviews
                    </Link>
                  </li>

                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/bonus/survey-for-leaders")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/bonus/survey-for-leaders"
                    >
                      Survey
                    </Link>
                  </li>

                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/bonus/view-evaluation-bonus-payout")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/bonus/view-evaluation-bonus-payout"
                    >
                      Evaluation
                    </Link>
                  </li>

                  {/* <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/bonus/view-bonus-payout-breakdown")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/bonus/view-bonus-payout-breakdown"
                    >
                      Bonus Breakdown
                    </Link>
                  </li> */}

                  {/* <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/bonus/view-bonus-report")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/bonus/view-bonus-report"
                    >
                      Bonus Report
                    </Link>
                  </li> */}
                </ul>
              </Collapse>
            </li>
          ) : (
            ""
          )}

          {/* Temporarily disable Human Resources tab for emergency emp request */}
          {/* {user && (user.role === "super admin" || user.role === "hr") ? (
            <li
              className={
                this.isPathActive("/human-resources")
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <div
                className={
                  this.state.humanResourcesMenu
                    ? "nav-link menu-expanded"
                    : "nav-link"
                }
                onClick={() => this.toggleMenuState("humanResourcesMenu")}
                data-toggle="collapse"
              >
                <i className="mdi mdi-human-handsup menu-icon"></i>
                <span className="menu-title">Human Resources </span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.humanResourcesMenu}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/human-resources/code")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/human-resources/code"
                    >
                      Emergency Request
                    </Link>
                  </li>

                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/human-resources/list/code")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/human-resources/list/code"
                    >
                      Emergency Request List
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </li>
          ) : (
            ""
          )} */}
          <li className={this.isPathActive("/hr") ? "nav-item active" : "nav-item"}>
            <div
              className={this.state.hrMenuOpen ? "nav-link menu-expanded" : "nav-link"}
              onClick={() => this.toggleMenuState("hrMenuOpen")}
              data-toggle="collapse"
            >
              <i className="mdi mdi-human-handsup menu-icon"></i>
              <span className="menu-title">HR Links</span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.hrMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={this.isPathActive("/hr/employees") ? "nav-link active" : "nav-link"}
                    to="/hr/employees"
                  >
                    HR Employees
                  </Link>
                </li>
                {user && user.role === "super admin" ? (
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/hr/management") ? "nav-link active" : "nav-link"
                      }
                      to="/hr/management"
                    >
                      {" "}
                      HR Management{" "}
                    </Link>
                  </li>
                ) : (
                  ""
                )}
              </ul>
            </Collapse>
          </li>

          {user && user.role !== "employee" ? (
            <li className={this.isPathActive("/report") ? "nav-item active" : "nav-item"}>
              <div
                className={this.state.reportPagesMenuOpen ? "nav-link menu-expanded" : "nav-link"}
                onClick={() => this.toggleMenuState("reportPagesMenuOpen")}
                data-toggle="collapse"
              >
                <i className="mdi mdi-message-reply-text menu-icon"></i>
                <span className="menu-title"> Reports </span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.reportPagesMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    <Link
                      className={
                        this.isPathActive("/report/accounting-report")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/report/accounting-report"
                    >
                      Accounting Report
                    </Link>
                  </li>

                  {user &&
                    (user.role === "super admin" || user.role === "district manager" ? (
                      <li className="nav-item">
                        {" "}
                        <Link
                          className={
                            this.isPathActive("/report/list-stores")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to="/report/list-stores"
                        >
                          MESReports
                        </Link>
                      </li>
                    ) : (
                      ""
                    ))}
                </ul>
              </Collapse>
            </li>
          ) : (
            ""
          )}

          {/* <li
            className={
              this.isPathActive("/training") ? "nav-item active" : "nav-item"
            }
          >
            <a
              href="https://veltron.document360.io/v1/en"
              target="_blank"
              rel="noopener noreferrer"
              className="nav-link active"
            >
              <i className="mdi mdi-book-open-page-variant menu-icon"></i>
              <span className="menu-title">Knowledge Base</span>
              <i className="menu-arrow"></i>
            </a>
          </li> */}
          {/* {user && user.role === "super admin" ? (
            <li
              className={
                this.isPathActive("/version-updates")
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <Link
                className={
                  this.isPathActive("/version-updates")
                    ? "nav-link active"
                    : "nav-link"
                }
                to="/version-updates"
              >
                <i className="mdi mdi-arrange-bring-forward menu-icon"></i>
                <span className="menu-title">Version Updates</span>
                <i className="menu-arrow"></i>
              </Link>
            </li>
          ) : (
            ""
          )} */}

          {user && user.role !== "employee" ? (
            <li className={this.isPathActive("/feedback") ? "nav-item active" : "nav-item"}>
              <Link
                className={this.isPathActive("/feedback") ? "nav-link active" : "nav-link"}
                to="/feedback"
              >
                <i className="mdi mdi-comment-question-outline menu-icon"></i>
                <span className="menu-title"> Feedback </span>
                <i className="menu-arrow"></i>
              </Link>
            </li>
          ) : (
            ""
          )}

          {user && (user.role === "operations" || user.role === "super admin") ? (
            <li
              className={
                this.isPathActive("/system-configuration") ? "nav-item active" : "nav-item"
              }
            >
              <div
                className={
                  this.state.systemConfigurationMenuOpen ? "nav-link menu-expanded" : "nav-link"
                }
                onClick={() => this.toggleMenuState("systemConfigurationMenuOpen")}
                data-toggle="collapse"
              >
                <i className="mdi mdi-settings menu-icon"></i>
                <span className="menu-title">Portal Configuration</span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.systemConfigurationMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/system-configuration/all")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/system-configuration/all"
                    >
                      Portal Settings
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/system-configuration/employee")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/system-configuration/employee"
                    >
                      Employees Settings
                      {/* /employee-configuration/all */}
                    </Link>
                  </li>

                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/system-configuration/bonus/default-page")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/system-configuration/bonus/default-page"
                    >
                      Bonus Payout
                    </Link>
                  </li>

                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/system-configuration/kpis/default")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/system-configuration/kpis/default"
                    >
                      Bonus KPIs
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </li>
          ) : (
            ""
          )}
          <li className={"nav-item sidebar-actions"} onClick={this.logout}>
            <div className={"nav-link"}>
              <i className="mdi mdi-logout-variant menu-icon"></i>
              <span className="menu-title">Sign Out</span>
              {/* <i className="menu-arrow"></i> */}
            </div>
          </li>

          <li className="nav-item">
            {" "}
            <Link
              className={
                this.isPathActive("/form-manager/closing-report-status")
                  ? "nav-link active"
                  : "nav-link"
              }
              to="/form-manager/closing-report-status"
            >
              Closing Report Status
            </Link>
          </li>
        </ul>
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add className 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  }
}

export default withRouter(Sidebar);
