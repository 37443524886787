import React, { Component, Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Spinner from "../app/shared/Spinner";
import Authorization from "../Helpers/authorization";

const Dashboard = lazy(() => import("./dashboard/Dashboard"));
const EmployeeDashboard = lazy(() => import("./dashboard/EmployeeDashboard"));

const Notifications = lazy(() => import("./notifications/Notifications"));

const PendingPayment = lazy(() => import("./financials/PendingPayments"));
const ViewPendingPaymentsStore = lazy(() => import("./financials/StorePendingPayments"));

const DeletedPayment = lazy(() => import("./financials/DeletedPayments"));
const ViewDeletedPaymentsStore = lazy(() => import("./financials/StoreDeletedPayments"));

const ScheduledPayment = lazy(() => import("./financials/ScheduledPayments"));
const ViewScheduledPaymentsStore = lazy(() => import("./financials/StoreScheduledPayments"));

const PaymentHistories = lazy(() => import("./financials/PaymentHistory"));
const ViewPaymentHistoriesStore = lazy(() => import("./financials/StorePaymentHistories"));

const ExpensesView = lazy(() => import("./financials/ExpensesViewer"));
const ViewExpensesStore = lazy(() => import("./financials/StoreExpenses"));
const ViewExpensesStoreDoc = lazy(() => import("./financials/StoreExpensesDoc"));

const ReportHistoriesView = lazy(() => import("./financials/ReportsHistories"));
const ViewReportStoreList = lazy(() => import("./financials/StoreReportList"));
const ViewStoreReportHistories = lazy(() => import("./financials/StoreReportHistories"));

const EditCatering = lazy(() => import("./catering/EditCatering"));

const CateringPayment = lazy(() => import("./catering/CateringPayment"));

const ConfigureCateringPayment = lazy(() => import("./catering/ConfigureCatering"));

const CateringPaymentCode = lazy(() => import("./catering/CateringPaymentCode"));

const CreateCateringPayment = lazy(() => import("./catering/CreateCatering"));

const CateringPendingList = lazy(() => import("./catering/PendingList"));
const CateringPending = lazy(() => import("./catering/Pending"));
const CateringScheduledList = lazy(() => import("./catering/ScheduledFailedList"));
const CateringScheduled = lazy(() => import("./catering/ScheduledFailedPending"));

const ScheduledEvents = lazy(() => import("./catering/ScheduledEvents"));

const EmergencyCodeReport = lazy(() => import("./financials/emergency/EmergencyCodeReport"));

const InstantAudits = lazy(() => import("./financials/InstantAudits"));

const InstantTransactionHistory = lazy(() => import("./financials/InstantTransaction"));
const ViewAllInstantAuditsStore = lazy(() => import("./financials/ViewAllInstantAuditsStore"));

const AddNewInstantAudit = lazy(() => import("./financials/AddNewInstantAuditForStore"));

// Login
const Login = lazy(() => import("./user-pages/Login"));
const AdminLogin = lazy(() => import("./user-pages/AdminLogin"));
const ForgotPassword = lazy(() => import("./user-pages/ForgotPassword"));
const ResetPassword = lazy(() => import("./user-pages/ResetPassword"));
const FirstTimeLogin = lazy(() => import("./user-pages/FirstTimeLogin.js"));

// Employees
const Employees = lazy(() => import("./employee/ViewEmployees"));
// const Employees = lazy(() => import("./financials/PaymentHistory"));
const ViewMyProfile = lazy(() => import("./employee/ViewMyProfile"));
const AddEmployee = lazy(() => import("./employee/AddEmployee"));

const CreateBulkUpload = lazy(() => import("./employee/bulk/CreateBulkUpload"));

const EditEmployee = lazy(() => import("./employee/EditEmployee"));
const ViewEmployee = lazy(() => import("./employee/ViewEmployee"));
const ViewApplicationEntries = lazy(() => import("./employee/application/ShowApplicationEntries"));
const ViewStoreApplications = lazy(() => import("./employee/application/ViewStoreApplications"));
const ViewStoreApplicationDetails = lazy(() =>
  import("./employee/application/ViewStoreApplicationDetails")
);

const EditStoreApplicationDetails = lazy(() =>
  import("./employee/application/EditStoreApplicationDetails")
);

const CreateRateMyBoss = lazy(() => import("./employee/survey/CreateRateMyBoss"));

const RateYourLeaders = lazy(() => import("./employee/survey/RateYourLeaders"));

const RateYourLeadersSpanish = lazy(() =>
  import("./employee/survey/languageSurvey/spanish/RateYourLeadersSpanish")
);
const RateYourLeadersEnglish = lazy(() =>
  import("./employee/survey/languageSurvey/english/RateYourLeadersEnglish")
);

const ResponseSummary = lazy(() => import("./employee/survey/answer/ResponseSummary"));

// HR Links
const HREmployees = lazy(() => import("./hr/HREmployees"));
const HRManagement = lazy(() => import("./hr/HRManagement"));

// Human Resources
const EmergencyCode = lazy(() => import("./human-resources/EmergencyCode"));
const EmergencyCodeList = lazy(() => import("./human-resources/EmergencyCodeList"));

// Accounting Closing Report
const AccountingReport = lazy(() => import("./accounting/ClosingReportDetails"));

const ViewAccountingReportDetails = lazy(() => import("./accounting/ViewClosingReportDetails"));

const ViewAndDownloadReportDetails = lazy(() =>
  import("./accounting/ViewAndDownloadReportDetails")
);

const SalesPercentageReportStore = lazy(() => import("./accounting/SalesReportStore"));

const SalesPercentageReportList = lazy(() => import("./accounting/SalesReportList"));

const SalesPercentageReportDetails = lazy(() => import("./accounting/SalesReportDetails"));

const BonusSelector = lazy(() => import("./bonus/BonusSelector"));

const BonusBulkUpload = lazy(() => import("./bonus/upload/Bulk"));

const SelectViewBonus = lazy(() => import("./bonus/SelectViewBonus"));

const ViewBonusBreakdown = lazy(() => import("./bonus/breakdown/ViewBonusBreakdown"));

const ViewBulkUploadBreakdown = lazy(() => import("./bonus/breakdown/ViewBulkUploadBreakdown"));

const ViewBonusReports = lazy(() => import("./bonus/reports/ViewBonusReports"));

const ViewBonusStoreList = lazy(() => import("./bonus/reports/ViewBonusStoreList"));

const ViewBonusReportHistories = lazy(() => import("./bonus/reports/ViewBonusReportHistories"));

const BonusBreakdown = lazy(() => import("./bonus/breakdown/BonusBreakdown"));

const CreateBonusPayoutData = lazy(() => import("./bonus/payout/CreateBonusPayoutData"));

const ViewBonusPayout = lazy(() => import("./bonus/payout/ViewBonusPayout"));

const ReviewsBonusPayout = lazy(() => import("./bonus/reviews/ReviewsBonusPayout"));

const EvaluationBonusPayout = lazy(() => import("./bonus/evaluation/EvaluationBonusPayout"));

const SurveyForLeaders = lazy(() => import("./bonus/survey/SurveyForLeaders"));

// Training
const Training = lazy(() => import("./training/Training"));

// System Configuration
const Domains = lazy(() => import("./system-configuration/domain/Domains"));
const ViewDomains = lazy(() => import("./system-configuration/domain/ViewDomains"));
const ViewDomain = lazy(() => import("./system-configuration/domain/ViewDomain"));

const JobCodes = lazy(() => import("./system-configuration/job-code/JobCodes"));
const ViewJobCodes = lazy(() => import("./system-configuration/job-code/ViewJobCodes"));

const ViewJobCode = lazy(() => import("./system-configuration/job-code/ViewJobCode"));
const Store = lazy(() => import("./system-configuration/store/Store"));
const ViewStores = lazy(() => import("./system-configuration/store/ViewStores"));
const ViewStore = lazy(() => import("./system-configuration/store/ViewStore"));

const Corporations = lazy(() => import("./system-configuration/corporation/Corporations"));
const ViewCorporations = lazy(() => import("./system-configuration/corporation/ViewCorporations"));

const ViewCorporation = lazy(() => import("./system-configuration/corporation/ViewCorporation"));

const Districts = lazy(() => import("./system-configuration/district/Districts"));
const ViewDistricts = lazy(() => import("./system-configuration/district/ViewDistricts"));

const ViewDistrict = lazy(() => import("./system-configuration/district/ViewDistrict"));

const SystemConfig = lazy(() => import("./system-configuration/SystemConfig"));

const BonusPayoutConfig = lazy(() => import("./system-configuration/bonus/ViewBonusConfig"));

const BonusPayoutStoreList = lazy(() => import("./system-configuration/bonus/StoreList"));

const BonusPayoutConfigDefault = lazy(() => import("./system-configuration/bonus/Default"));

const BonusKPIConfigDefault = lazy(() => import("./system-configuration/bonus/kpi/Default"));

const CreateBonusPayoutConfig = lazy(() =>
  import("./system-configuration/bonus/CreateBonusConfig")
);

// Employee Configuration
const EmployeeConfiguration = lazy(() =>
  import("./system-configuration/employee-configuration/EmployeesConfig")
);

const States = lazy(() => import("./system-configuration/employee-configuration/state/States"));
const ViewStates = lazy(() =>
  import("./system-configuration/employee-configuration/state/ViewStates")
);

const ViewState = lazy(() =>
  import("./system-configuration/employee-configuration/state/ViewState")
);

const MaritalStatuses = lazy(() =>
  import("./system-configuration/employee-configuration/marital-status/MaritalStatuses")
);
const ViewMaritalStatuses = lazy(() =>
  import("./system-configuration/employee-configuration/marital-status/ViewMaritalStatuses")
);

const ViewMaritalStatus = lazy(() =>
  import("./system-configuration/employee-configuration/marital-status/ViewMaritalStatus")
);

const Genders = lazy(() => import("./system-configuration/employee-configuration/gender/Genders"));
const ViewGenders = lazy(() =>
  import("./system-configuration/employee-configuration/gender/ViewGenders")
);

const ViewGender = lazy(() =>
  import("./system-configuration/employee-configuration/gender/ViewGender")
);

const Ethnics = lazy(() => import("./system-configuration/employee-configuration/ethnic/Ethnics"));
const ViewEthnics = lazy(() =>
  import("./system-configuration/employee-configuration/ethnic/ViewEthnics")
);

const ViewEthnic = lazy(() =>
  import("./system-configuration/employee-configuration/ethnic/ViewEthnic")
);

// form manager
const CreditMemoReport = lazy(() => import("./form-manager/forms/CreditMemoReport.js"));

const JobCodeRequest = lazy(() => import("./form-manager/forms/JobCodeRequest.js"));

const MeetingsManagerReport = lazy(() => import("./form-manager/forms/MeetingsManagerReport.js"));

const CreditCardDispute = lazy(() => import("./form-manager/forms/CreditCardDispute.js"));

const TrainingManagerDailyReport = lazy(() =>
  import("./form-manager/forms/TrainingManagerDailyReport.js")
);

const ManagerCardRequest = lazy(() => import("./form-manager/forms/ManagerCardRequest.js"));

const SuperSourceOrder = lazy(() => import("./form-manager/forms/SuperSourceOrder.js"));

const DailyInventory = lazy(() => import("./form-manager/forms/DailyInventory.js"));

const UniformsOrder = lazy(() => import("./form-manager/forms/UniformsOrder.js"));

const DepositSlips = lazy(() => import("./form-manager/forms/DepositSlips.js"));

const GoldenPot = lazy(() => import("./form-manager/forms/GoldenPot.js"));

const OnlineOrderingIssue = lazy(() => import("./form-manager/forms/OnlineOrderingIssue.js"));

const OpenFood = lazy(() => import("./form-manager/forms/OpenFood.js"));

const FileSubmission = lazy(() => import("./form-manager/forms/FileSubmission.js"));

const InstantPayCardRequest = lazy(() => import("./form-manager/forms/InstantPayCardRequest.js"));

const CoachServerCertificate = lazy(() =>
  import("./form-manager/forms/CoachServerCertificateRequest.js")
);

const AllCheckList = lazy(() => import("./form-manager/forms/checkLists/AllCheckList.js"));

const OpeningCheckList = lazy(() => import("./form-manager/forms/OpeningCheckList.js"));

const ChangeCheckList = lazy(() => import("./form-manager/forms/ChangeCheckList.js"));

const ClosingCheckList = lazy(() => import("./form-manager/forms/ClosingCheckList.js"));

const MaintenanceIssue = lazy(() => import("./form-manager/forms/MaintenanceIssue.js"));

// District Manager Forms
const InspectionReport = lazy(() => import("./form-manager/forms/InspectionReport.js"));

const KitchenInspectionReport = lazy(() =>
  import("./form-manager/forms/KitchenInspectionReport.js")
);

const DailyReportSheet = lazy(() => import("./form-manager/forms/DailyReportSheet.js"));

const DistrictKitchenManager = lazy(() => import("./form-manager/forms/DistrictKitchenManager.js"));

const InspectionCheckList = lazy(() => import("./form-manager/forms/InspectionCheckList.js"));

const AlohaTemporaryLocationCode = lazy(() =>
  import("./form-manager/forms/AlohaTemporaryLocationCode.js")
);

const StaffMoveChange = lazy(() => import("./form-manager/forms/StaffMoveChange.js"));

const DailyRecruitment = lazy(() => import("./form-manager/forms/DailyRecruitment.js"));

const RepairMaintenanceReport = lazy(() =>
  import("./form-manager/forms/RepairMaintenanceReport.js")
);

// MESReport
const MESReport = lazy(() => import("./report/MESReport.js"));
const ListStoresForReport = lazy(() => import("./report/ListStoresForReport.js"));

const NewAccountingReport = lazy(() => import("./report/AccountingReport.js"));

// Training
const ManagerRequest = lazy(() => import("./form-manager/forms/ManagerRequest.js"));

// old forms
const RecipeBookDaily = lazy(() => import("./form-manager/forms/RecipeBookDaily.js"));

const ApplicationForm = lazy(() => import("./form-manager/forms/ApplicationForm.js"));
const CheckList = lazy(() => import("./form-manager/forms/CheckList.js"));
const DailySales = lazy(() => import("./form-manager/forms/DailySales.js"));
const DailyMarketing = lazy(() => import("./form-manager/forms/DailyMarketing.js"));
const DailyManagers = lazy(() => import("./form-manager/forms/DailyManagers.js"));
const CateringSales = lazy(() => import("./form-manager/forms/CateringSales.js"));
const GasketsForm = lazy(() => import("./form-manager/forms/GasketsForm.js"));
const AssistantDistrict = lazy(() => import("./form-manager/forms/AssistantDistrict.js"));
const CasiCielo = lazy(() => import("./form-manager/forms/CasiCielo.js"));

const CDP = lazy(() => import("./form-manager/forms/CDP.js"));

const AuditCheckList = lazy(() => import("./form-manager/forms/AuditCheckList.js"));
const InspectionDistrict = lazy(() => import("./form-manager/forms/InspectionDistrict.js"));
const RestaurantOperations = lazy(() => import("./form-manager/forms/RestaurantOperations.js"));
const RSVP = lazy(() => import("./form-manager/forms/RSVP.js"));
const WeeklyInventory = lazy(() => import("./form-manager/forms/WeeklyInventory.js"));

const ClosingReport = lazy(() => import("./form-manager/ClosingReport"));
const NewClosingReport = lazy(() => import("./form-manager/NewClosingReport"));
const ClosingReportDetails = lazy(() => import("./form-manager/ClosingReportDetails"));

const ExpensesReport = lazy(() => import("./form-manager/ExpensesReport"));

const RestaurantManager = lazy(() => import("./form-manager/RestaurantManager"));

const CrunchTime = lazy(() => import("./form-manager/CrunchTime"));
const DistrictManager = lazy(() => import("./form-manager/DistrictManager"));
const HRManager = lazy(() => import("./form-manager/HRManager"));

const ClosingReportStatus = lazy(() => import("./form-manager/ClosingReportStatus"));

const ClosingReportIssues = lazy(() =>
  import("./form-manager/ClosingReportIssues/ClosingReportIssues")
);

const Maintenance = lazy(() => import("./form-manager/MaintenanceIssues/MaintenanceForm"));

// PDF Documentation
const PreviewPDF = lazy(() => import("./form-manager/closingReport/PreviewDownload"));

const SalesReportPDF = lazy(() => import("./financials/ViewReportHistory"));
const EmergencyReportPDf = lazy(() => import("./financials/ViewEmergencyReport"));

// View version updates
const ViewVersionUpdates = lazy(() => import("./version-updates/View"));
const CreateVersionUpdates = lazy(() => import("./version-updates/Create"));

// Feedback
const ViewFeedbacks = lazy(() => import("./feedback/View"));
const CreatedFeedbacks = lazy(() => import("./feedback/Create"));

const Admin = Authorization(["admin", "super admin", "operations", "accounting"]);

class AppRoutes extends Component {
  render() {
    return (
      <Suspense fallback={<Spinner />}>
        <Switch>
          {/* <AuthProvider> */}
          {/* <Route path="/">
              {User ? (
                <Redirect to="/dashboard" />
              ) : (
                <Redirect to="/home/login" />
              )}
            </Route> */}
          {/* Authentication */}
          <Route path="/home/login" component={Login} />
          <Route path="/home/admin/login" component={AdminLogin} />
          <Route exact path="/password/forgot-password" component={ForgotPassword} />
          <Route path="/password/reset-password/:token" component={ResetPassword} />
          <Route path="/auth/first-login" component={FirstTimeLogin} />
          {/* <GuardedRoute
            path="/dashboard"
            component={Dashboard}
            user={this.state.user}
          /> */}
          <Route exact path="/dashboard" component={Dashboard} />
          <Route exact path="/employee/dashboard" component={EmployeeDashboard} />
          {/* System Configuration */}
          <Route path="/system-configuration/domains" component={Admin(Domains)} />
          <Route path="/system-configuration/view-domains" component={Admin(ViewDomains)} />
          <Route path="/system-configuration/view-domain/:id" component={Admin(ViewDomain)} />
          <Route path="/system-configuration/job-codes" component={Admin(JobCodes)} />
          <Route path="/system-configuration/view-job-codes" component={Admin(ViewJobCodes)} />
          <Route path="/system-configuration/view-job-code/:id" component={Admin(ViewJobCode)} />
          <Route path="/system-configuration/store" component={Admin(Store)} />
          <Route path="/system-configuration/view-stores" component={Admin(ViewStores)} />
          <Route path="/system-configuration/view-store/:id" component={Admin(ViewStore)} />
          <Route path="/system-configuration/corporations" component={Admin(Corporations)} />
          <Route
            path="/system-configuration/view-corporations"
            component={Admin(ViewCorporations)}
          />
          <Route
            path="/system-configuration/view-corporation/:id"
            component={Admin(ViewCorporation)}
          />

          <Route path="/system-configuration/districts" component={Admin(Districts)} />
          <Route path="/system-configuration/view-districts" component={Admin(ViewDistricts)} />
          <Route path="/system-configuration/view-district/:id" component={Admin(ViewDistrict)} />

          <Route path="/system-configuration/all" component={Admin(SystemConfig)} />

          <Route
            path="/system-configuration/bonus/create-config"
            component={Admin(CreateBonusPayoutConfig)}
          />

          <Route
            path="/system-configuration/bonus/default-page"
            component={Admin(BonusPayoutConfigDefault)}
          />

          <Route
            path="/system-configuration/kpis/default"
            component={Admin(BonusKPIConfigDefault)}
          />

          <Route path="/system-configuration/bonus/view" component={Admin(BonusPayoutStoreList)} />

          <Route
            path="/system-configuration/bonus/view-config/:id"
            component={Admin(BonusPayoutConfig)}
          />

          {/* Employee Configuration */}
          <Route path="/system-configuration/employee" component={Admin(EmployeeConfiguration)} />

          <Route path="/employee-configuration/states" component={Admin(States)} />
          <Route path="/employee-configuration/view-states" component={Admin(ViewStates)} />
          <Route path="/employee-configuration/view-state/:id" component={Admin(ViewState)} />
          <Route
            path="/employee-configuration/marital-statuses"
            component={Admin(MaritalStatuses)}
          />
          <Route
            path="/employee-configuration/view-marital-statuses"
            component={Admin(ViewMaritalStatuses)}
          />
          <Route
            path="/employee-configuration/view-marital-status/:id"
            component={Admin(ViewMaritalStatus)}
          />
          <Route path="/employee-configuration/genders" component={Admin(Genders)} />
          <Route path="/employee-configuration/view-genders" component={Admin(ViewGenders)} />
          <Route path="/employee-configuration/view-gender/:id" component={Admin(ViewGender)} />
          <Route path="/employee-configuration/ethnics" component={Admin(Ethnics)} />
          <Route path="/employee-configuration/view-ethnics" component={Admin(ViewEthnics)} />
          <Route path="/employee-configuration/view-ethnic/:id" component={Admin(ViewEthnic)} />
          {/* <PrivateRoute exact path="/dashboard" component={ Dashboard } /> */}
          {/* Employees */}
          <Route path="/employees/list" component={Employees} />
          <Route path="/employees/add" component={AddEmployee} />

          <Route path="/employees/bulk/upload" component={CreateBulkUpload} />

          <Route path="/employee/edit/:slug" component={EditEmployee} />
          <Route path="/employees/view/:slug" component={ViewEmployee} />
          <Route path="/employee/view/my-profile/:slug" component={ViewMyProfile} />
          <Route path="/employees/application" component={ViewApplicationEntries} />
          <Route path="/employees/entries/:store_id" component={ViewStoreApplications} />
          <Route path="/employees/details/:store_id" component={ViewStoreApplicationDetails} />

          <Route path="/employees/edit-details/:store_id" component={EditStoreApplicationDetails} />

          <Route path="/employees/rate-your-leaders/create" component={CreateRateMyBoss} />

          <Route
            path="/employees/rate-your-leaders/begin/spanish"
            component={RateYourLeadersSpanish}
          />
          <Route
            path="/employees/rate-your-leaders/begin/english"
            component={RateYourLeadersEnglish}
          />

          <Route path="/employees/rate-your-leaders" component={RateYourLeaders} />

          <Route path="/employees/survey/response-summary" component={ResponseSummary} />

          {/* HR Links */}
          <Route path="/hr/employees" component={HREmployees} />
          <Route path="/hr/management" component={HRManagement} />
          {/* Human Resources*/}
          <Route path="/human-resources/code" component={EmergencyCode} />
          <Route path="/human-resources/list/code" component={EmergencyCodeList} />
          {/* Accounting Closing Report */}
          <Route path="/accounting/report" component={AccountingReport} />
          <Route
            path="/accounting/sales-report/:store_id"
            component={ViewAccountingReportDetails}
          />
          <Route
            path="/accounting/view-sales-report/:store_id/:id"
            component={ViewAndDownloadReportDetails}
          />

          <Route path="/percentage/report" component={SalesPercentageReportStore} />

          <Route path="/percentage/view-report/:store_id" component={SalesPercentageReportList} />

          <Route
            path="/percentage/view-details/:store_id/:id"
            component={SalesPercentageReportDetails}
          />

          <Route path="/bonus-payout" component={BonusSelector} />

          <Route path="/bonus/bulk-upload" component={BonusBulkUpload} />

          <Route path="/bonus/view-select-bonus-payout" component={SelectViewBonus} />

          <Route path="/bonus/view-bonus-payout-breakdown" component={ViewBonusBreakdown} />

          <Route path="/bonus/view-bulk-upload-breakdown" component={ViewBulkUploadBreakdown} />

          <Route path="/bonus-payout-breakdown" component={BonusBreakdown} />

          <Route path="/bonus/create-bonus-payout" component={CreateBonusPayoutData} />

          <Route path="/view-bonus-payout" component={ViewBonusPayout} />

          <Route path="/bonus/view-reviews-bonus-payout" component={ReviewsBonusPayout} />

          <Route path="/bonus/view-evaluation-bonus-payout" component={EvaluationBonusPayout} />

          <Route path="/bonus/survey-for-leaders" component={SurveyForLeaders} />

          <Route path="/bonus/view-bonus-report" component={ViewBonusReports} />

          <Route path="/bonus/store-list" component={ViewBonusStoreList} />

          <Route path="/bonus/view-bonus-histories-store" component={ViewBonusReportHistories} />

          {/* Training */}
          <Route path="/training" component={Training} />
          {/* Form manager */}
          <Route path="/form-manager/credit-memo-report" component={CreditMemoReport} />
          <Route path="/form-manager/job-code-request" component={JobCodeRequest} />
          <Route path="/form-manager/meetings-manager-report" component={MeetingsManagerReport} />
          <Route path="/form-manager/credit-card-dispute" component={CreditCardDispute} />
          <Route
            path="/form-manager/training-manager-daily-report"
            component={TrainingManagerDailyReport}
          />
          <Route path="/form-manager/manager-card-request" component={ManagerCardRequest} />
          <Route path="/form-manager/super-source-order" component={SuperSourceOrder} />
          <Route path="/form-manager/daily-inventory" component={DailyInventory} />
          <Route path="/form-manager/uniforms-order" component={UniformsOrder} />
          <Route path="/form-manager/deposit-slips" component={DepositSlips} />
          <Route path="/form-manager/golden-pot" component={GoldenPot} />
          <Route path="/form-manager/online-ordering" component={OnlineOrderingIssue} />
          <Route path="/form-manager/open-food" component={OpenFood} />
          <Route path="/form-manager/file-submission" component={FileSubmission} />
          <Route path="/form-manager/instant-pay-card-request" component={InstantPayCardRequest} />
          <Route path="/form-manager/coach-server-certificate" component={CoachServerCertificate} />
          <Route path="/form-manager/all-checklist" component={AllCheckList} />
          <Route path="/form-manager/closing-checklist" component={ClosingCheckList} />
          <Route path="/form-manager/change-checklist" component={ChangeCheckList} />
          <Route path="/form-manager/opening-checklist" component={OpeningCheckList} />
          <Route path="/form-manager/maintenance-issue" component={MaintenanceIssue} />
          {/* District Manager Forms */}
          <Route path="/form-manager/inspection-report" component={InspectionReport} />
          <Route
            path="/form-manager/kitchen-inspection-report"
            component={KitchenInspectionReport}
          />
          <Route path="/form-manager/daily-report-sheet" component={DailyReportSheet} />
          <Route path="/form-manager/district-kitchen-manager" component={DistrictKitchenManager} />
          <Route path="/form-manager/inspection-checklist" component={InspectionCheckList} />
          <Route path="/form-manager/aloha-location-code" component={AlohaTemporaryLocationCode} />
          <Route path="/form-manager/staff-move-change" component={StaffMoveChange} />
          <Route path="/form-manager/daily-recruitment" component={DailyRecruitment} />
          <Route
            path="/form-manager/repair-maintenance-report"
            component={RepairMaintenanceReport}
          />
          {/* Training */}
          <Route path="/form-manager/manager-request" component={ManagerRequest} />

          <Route path="/report/mes/:id" component={MESReport} />

          <Route path="/report/list-stores" component={ListStoresForReport} />

          <Route path="/report/accounting-report" component={NewAccountingReport} />

          {/* Old forms */}
          <Route path="/form-manager/recipe-book-daily" component={RecipeBookDaily} />
          <Route path="/form-manager/application-form" component={ApplicationForm} />
          <Route path="/form-manager/checklist" component={CheckList} />
          <Route path="/form-manager/daily-sales" component={DailySales} />
          <Route path="/form-manager/daily-marketing" component={DailyMarketing} />
          <Route path="/form-manager/daily-managers" component={DailyManagers} />
          <Route path="/form-manager/catering-sales" component={CateringSales} />
          <Route path="/form-manager/gaskets-form" component={GasketsForm} />
          <Route path="/form-manager/assistant-district" component={AssistantDistrict} />
          <Route path="/form-manager/casicielo" component={CasiCielo} />
          <Route path="/form-manager/cdp" component={CDP} />
          <Route path="/form-manager/audit-checklist" component={AuditCheckList} />
          <Route path="/form-manager/inspection-district" component={InspectionDistrict} />
          <Route path="/form-manager/restaurant-operations" component={RestaurantOperations} />
          <Route path="/form-manager/rsvp" component={RSVP} />
          <Route path="/form-manager/weekly-inventory" component={WeeklyInventory} />
          <Route path="/form-manager/closing-report" component={ClosingReport} />

          <Route path="/form-manager/new-closing-report" component={NewClosingReport} />

          <Route path="/form-manager/closing-report-details" component={ClosingReportDetails} />
          <Route path="/form-manager/expenses-report" component={ExpensesReport} />
          <Route path="/form-manager/restaurant-manager" component={RestaurantManager} />
          <Route path="/form-manager/crunch-time" component={CrunchTime} />
          <Route path="/form-manager/district-manager" component={DistrictManager} />
          <Route path="/form-manager/hr-manager" component={HRManager} />

          <Route path="/form-manager/closing-report-status" component={ClosingReportStatus} />

          <Route path="/form-manager/closing-report-issues" component={ClosingReportIssues} />

          <Route path="/form-manager/maintenance" component={Maintenance} />

          {/* {Notifications} */}
          <Route path="/notifications" component={Notifications} />
          {/*Financial Routes */}
          <Route path="/financials/pending-payments" component={PendingPayment} />

          <Route path="/financials/deleted-payments" component={DeletedPayment} />
          <Route path="/financials/scheduled-payments" component={ScheduledPayment} />
          <Route path="/financials/expenses-viewers" component={ExpensesView} />
          <Route path="/financials/view-expenses-store/:store_id" component={ViewExpensesStore} />
          <Route path="/financials/view-store-report" component={ViewReportStoreList} />
          <Route
            path="/financials/view-report-store/:store_id/:id"
            component={ReportHistoriesView}
          />
          <Route
            path="/financials/view-report-histories-store/:store_id"
            component={ViewStoreReportHistories}
          />
          <Route
            path="/financials/store/doc/:store_id/:expense_id"
            component={ViewExpensesStoreDoc}
          />
          <Route
            path="/financials/view-pending-payments-store/:store_id"
            component={ViewPendingPaymentsStore}
          />

          <Route
            path="/financials/view-deleted-payments-store/:store_id"
            component={ViewDeletedPaymentsStore}
          />

          <Route
            path="/financials/view-scheduled-payments-store/:store_id"
            component={ViewScheduledPaymentsStore}
          />
          <Route
            path="/financials/view-payment-histories-store/:store_id"
            component={ViewPaymentHistoriesStore}
          />
          <Route path="/financials/payment-history" component={PaymentHistories} />
          <Route path="/financials/view-closing-report" component={SalesReportPDF} />
          <Route path="/financials/view-emergency-report" component={EmergencyReportPDf} />
          <Route path="/catering/catering-payment" component={CateringPayment} />
          <Route path="/catering/catering-payment-code/:store_id" component={CateringPaymentCode} />
          <Route
            path="/catering/create-catering-payment/:store_id"
            component={CreateCateringPayment}
          />
          <Route path="/catering/pending-list" component={CateringPendingList} />
          <Route path="/catering/pending/:store_id" component={CateringPending} />
          <Route path="/catering/scheduled-list" component={CateringScheduledList} />
          <Route path="/catering/scheduled/:store_id" component={CateringScheduled} />
          <Route path="/catering/scheduled-events/" component={ScheduledEvents} />

          <Route path="/financials/emergency-code-report" component={EmergencyCodeReport} />
          {/* <Route
            path="/financials/configure-catering-payment-store/:store_id/:code"
            component={ConfigureCateringPayment}
          /> */}
          <Route path="/catering/edit-catering-payment" component={EditCatering} />
          <Route
            path="/catering/configure-catering-payment-store"
            component={ConfigureCateringPayment}
          />
          <Route path="/financials/instant-audits" component={InstantAudits} />

          <Route
            path="/financials/instant-transaction-history"
            component={InstantTransactionHistory}
          />

          <Route
            path="/financials/view-all-instant-audits-store/:store_id"
            component={ViewAllInstantAuditsStore}
          />
          <Route
            path="/financials/add-new-instant-audits/:store_id"
            component={AddNewInstantAudit}
          />
          <Route path="/form-manager/preview-closing-report" component={PreviewPDF} />

          <Route path="/version-updates" component={ViewVersionUpdates} />
          <Route path="/create-version-updates" component={CreateVersionUpdates} />

          <Route path="/feedback" component={CreatedFeedbacks} />
          <Route path="/feedback-view" component={ViewFeedbacks} />
          {/* </AuthProvider> */}
          <Redirect to="/dashboard" />
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;
