import React, { useState, useEffect } from "react";
import { withRouter, useLocation } from "react-router-dom";
import "./App.scss";
import "../assets/styles/custom-styles.scss";
import AppRoutes from "./AppRoutes";
import Navbar from "./shared/Navbar";
import Sidebar from "./shared/Sidebar";
import Footer from "./shared/Footer";
import { Utility } from "../Helpers/utils";
import "react-confirm-alert/src/react-confirm-alert.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond/dist/filepond.min.css";
import "react-aspect-ratio/aspect-ratio.css";
import IdleTimerContainer from "../components/idleTimerContainer";
import { BonusProvider } from "../context/bonus/BonusContext";

const App = () => {
  const [isFullPageLayout, setIsFullPageLayout] = useState(false);

  const location = useLocation();

  useEffect(() => {
    console.log("ROUTE CHANGED");
    const body = document.querySelector("body");
    if (location.pathname === "/layout/RtlLayout") {
      body.classList.add("rtl");
    } else {
      body.classList.remove("rtl");
    }
    window.scrollTo(0, 0);
    const fullPageLayoutRoutes = [
      "/home/login",
      "/home/admin/login",
      "/password/forgot-password",
      "/password/reset-password/:token",
      "/auth/first-login",
      "/error-pages/error-404",
      "/error-pages/error-500",
      "/general-pages/landing-page",
      "/employees/rate-your-leaders/create",
      "/employees/rate-your-leaders/begin/spanish",
      "/employees/rate-your-leaders/begin/english",
      "/employees/rate-your-leaders",
      "/employees/survey/response-summary"
    ];
    for (let i = 0; i < fullPageLayoutRoutes.length; i++) {
      if (location.pathname === fullPageLayoutRoutes[i]) {
        setIsFullPageLayout(true);
        document
          .querySelector(".page-body-wrapper")
          .classList.add("full-page-wrapper");
        break;
      } else if (location.pathname.includes("/password/reset-password/")) {
        setIsFullPageLayout(true);
        document
          .querySelector(".page-body-wrapper")
          .classList.add("full-page-wrapper");
        break;
      } else {
        setIsFullPageLayout(false);
        document
          .querySelector(".page-body-wrapper")
          .classList.remove("full-page-wrapper");
      }
    }
  }, [location.pathname]);

  const navbarComponent = !isFullPageLayout ? <Navbar /> : "";
  const sidebarComponent = !isFullPageLayout ? (
    <Sidebar user={Utility.getUser()} />
  ) : (
    ""
  );
  const footerComponent = !isFullPageLayout ? <Footer /> : "";
  return (
    <BonusProvider>
      <div className="container-scroller">
        {Utility.getUser() ? <IdleTimerContainer></IdleTimerContainer> : ""}

        {navbarComponent}
        <div className="container-fluid page-body-wrapper">
          {sidebarComponent}
          <div className="main-panel">
            <div className="content-wrapper">
              <AppRoutes />
            </div>

            {footerComponent}
          </div>
        </div>
      </div>
    </BonusProvider>
  );
};

export default withRouter(App);
