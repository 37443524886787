import React, { createContext, useReducer } from "react";
import bonusReducer from "./BonusReducer";

const BonusContext = createContext();

export const BonusProvider = ({ children }) => {
  const initialStates = {
    year: "",
    quarter: "",
    storeId: "",
    months: {},
    sales: {},
    cogs: {},
    grossProfit: {},
    operatingExpenses: {},
    administrativeExpenses: {},
    totalExpenses: {},
    operatingIncome: {},
    admonFee: {},
    interest: {},
    subTotal: {},
    otherIncome: {},
    netProfit: {},
    bulkUpload: {},
  };

  const [state, dispatch] = useReducer(bonusReducer, initialStates);

  return (
    <BonusContext.Provider
      value={{
        ...state,
        dispatch,
      }}
    >
      {children}
    </BonusContext.Provider>
  );
};

export default BonusContext;
